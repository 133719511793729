import React, { Component } from 'react';

import Config from '../../Config.js'

import MsSignin from '../../resources/ms-symbollockup_signin_light.svg'

import './style.css';

class Login extends Component {
  redirectMS(){
    window.location.href = Config.apiEndpoint + "auth/ms/login?ref=" + window.location.pathname
  }

  render() {
    return (
      <div className="loginContainer">
        <div className="loginContent containerBox">
          <div className="loginHeaderLogo">
            <img src="https://selfservicethemes.s3.eu-central-1.amazonaws.com/Rolly_logo_R_rgb.png" alt="Rolly" />
          </div>
          <div className="loginOptions">
            <div className="microsoftLogin" onClick={() => { this.redirectMS() }}>
              <img className="microsoftLoginLogo" src={MsSignin} alt="Login with Microsoft" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

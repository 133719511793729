import React from 'react';
import translate from '../../utils/translate'

function NotFound(props) {
  return (
    <div className="errorContainer">
      <p className="App-intro center">
        {translate("PAGE_NOT_FOUND_LARGE")}
      </p>
    </div>
  );
}

export default NotFound;

/*
 *  Import views
 */
import NotFound from './views/notfound'
import Error from './views/error'
import LoginView from './views/login'
import Loading from './views/loading'
import PortalView from './views/portal'

/*
 *  Viewstates icons can be found here: https://material.io/resources/icons/?style=baseline
 *  Array object:
 *  {
 *     path: {string} URL Path ex: "login" for /login,
 *     viewstate: {string} Unique Viewstate ID,
 *     view: {React Component} React Component to render,
 *     navigation: {bool} Should it show up in the NAV,
 *     icon: {string} material.io logo for NAV ex: "dashboard",
 *     label: {string} Label in NAV "Home",
 *     header: {bool} should the header be visible on this page,
 *     sidebar: {bool} should the sidebar be visible on this page,
 *     windowTitle: {string} Window title,
 *     requireLogin: {bool} should it redirect to login page if user = null,
 *     activeViewstates: {array<string>} Viewstates that adds active tag to navigation item
 *  }
 */
const Config = {
  viewstates: [
    {
      path: "",                         //URL path. %homepage%/send
      viewstate: "VIEWSTATE_ROOT",      //Remember to create a viewstate below and link it here.
      view: PortalView,                   //View as a React Component
      navigation: true,                 //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                //Material.io icon
      label: "Home",                    //Label for navigation. Goes trough translate
      header: true,                     //Header visible
      sidebar: false,                    //Sidebar visible
      windowTitle: "Rolly Portal", //Window title
      requireLogin: true,              //Require login
      activeViewstates: [               //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_ROOT"
      ]
    },
    {
      path: "open",                         //URL path. %homepage%/send
      viewstate: "VIEWSTATE_OPEN",      //Remember to create a viewstate below and link it here.
      view: PortalView,                   //View as a React Component
      navigation: true,                 //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                //Material.io icon
      label: "Home",                    //Label for navigation. Goes trough translate
      header: true,                     //Header visible
      sidebar: false,                    //Sidebar visible
      windowTitle: "Rolly Portal", //Window title
      requireLogin: true,              //Require login
      activeViewstates: [               //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_OPEN"
      ]
    },
    {
      path: "login",
      viewstate: "VIEWSTATE_LOGIN",
      view: LoginView,
      navigation: false,
      icon: "account",
      label: "Login",
      header: false,
      sidebar: false,
      windowTitle: "Login",
      requireLogin: false,
      activeViewstates: [
        "VIEWSTATE_LOGIN"
      ]
    },
    {
      path: "timesheet",
      viewstate: "VIEWSTATE_ADMIN",
      view: NotFound,
      navigation: true,
      icon: "schedule",
      label: "Admin",
      header: true,
      sidebar: true,
      windowTitle: "Admin",
      requireLogin: true,
      activeViewstates: [
        "VIEWSTATE_ADMIN"
      ]
    },
  ],
  VIEWSTATE_NOTFOUND: NotFound,
  VIEWSTATE_ERROR: Error,
  VIEWSTATE_LOADING: Loading,
  VIEWSTATE_LOGIN: LoginView
}

export default Config;

import React, { Component } from 'react';
import { Form, Spinner, Button, Card } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';

import Config from '../../Config';
import translate from '../../utils/translate'
import { apiRequest } from '../../utils/api'

import './style.css';

class Portal extends Component {
  constructor(){
    super();
    this.state = {
      applications: [],
      editing: false,
    }
  }

  async componentDidMount(){
    const apps = await apiRequest({
      method: "GET",
      url: "portal/apps"
    })

    const action = this.props.args[0]

    if(action === "open"){
      const appId = this.props.args[1]
      const app = apps.find(row => row.eid === appId);
      if(app){
        window.location = app.url
      }
    }

    this.setState({
      applications: apps
    })
  }

  getLoadingApplicationCards(){
    return (
      <div>
        Loading
      </div>
    )
  }

  getApplicationCards(){
    const { applications } = this.state
    if(!applications || applications.length < 1){
      return (
        this.getLoadingApplicationCards()
      )
    }
    const applicationCards = applications.map((app) => {
      return (
        <div className="appCard">
          <Card style={{ width: '17rem' }}>
            <Card.Body>
              <Card.Title>{app.title}</Card.Title>
              <Card.Text>
                <div className="appDescription">
                  {app.description}
                </div>
              </Card.Text>
              <Button variant="primary" onClick={() => { window.open(app.url, "_blank") }}>
                Avaa
              </Button>
            </Card.Body>
          </Card>
        </div>
      )
    })
    return (
      <div className="appCardsContainer">
        {applicationCards}
      </div>
    )
  }

  render() {
    return (
      <div className="portalContainer">
        <div className="applications">
          {this.getApplicationCards()}
        </div>
      </div>
    );
  }
}

export default Portal;

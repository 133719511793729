import fi from '../translations/fi'

var lang = fi;

export function setLanguage(key){
  switch(key.toLowerCase()){
    case "fi":
    case "fi-fi":
      return lang = fi;
    default:
      console.warn("[Translate]: Tried to set language that does not exist.")
      return null;
  }
}

export default function translate(key){
  return lang[key] || key
}

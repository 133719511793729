import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';

import './style.css';

class Header extends Component {

  render() {
    return (
      <div className="adminHeaderContainer">
        <div className="adminHeaderLogo">
            <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 227.56 82" viewBox="0 0 227.56 82" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
                <g>
                    <path d="M133.89 57.87h7v8.52h-23.57v-8.52h7.05V8.52h-7.05V0h16.57v57.87zm93.67-31.18v-8.53h-23.25v8.53h6.89l-11.73 25.13-10.11-25.13h7v-8.53h-23.55v8.53h6.32l14.8 35.58-4.43 11.19h-9.36V82h25.42v-8.54h-6.48l22.7-46.77zm-64-18.17V0H147v8.52h7v49.35h-7v8.52h23.52v-8.52h-7zM53.74 57.89h6.91v8.5H35.22V57.9h8l-8.13-16.81H18.15v16.78h7.26v8.51H0v-8.5h8.64V13.3H0V4.76h40.38A18.16 18.16 0 0 1 41.74 41l2.57 5.33h3.88zM18.15 32.56h21.3a9.64 9.64 0 0 0 0-19.28h-21.3zm96 9.74A25.28 25.28 0 1 1 88.86 17a25.31 25.31 0 0 1 25.28 25.3zm-9.56 0A15.72 15.72 0 1 0 88.86 58a15.74 15.74 0 0 0 15.72-15.7z" fill="#ffffff" data-color="1"></path>
                </g>
            </svg>
        </div>
        <div className="adminHeaderContent">
          <h1>{(this.props&& this.props.notification)}</h1>
        </div>
        {(this.props.user)?
          (
            <div className="headerUsernameContainer">
              <div className="headerUsernameContainerIcon">{(this.props&& this.props.user&& <Icon>account_circle</Icon>)}</div>
              <div className="headerUsernameContainerName">{(this.props&& this.props.user&& this.props.user.username)}</div>
            </div>
          )
          :
          ""
        }
      </div>
    );
  }
}

export default Header;

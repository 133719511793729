import axios from 'axios'
import Config from '../Config'

/**
 *  Simple api request function that adds default settings to axios request to simplify usage.
 */
export const apiRequest = async (config) => {
  try {
    const options = {
      withCredentials: true,
      baseURL: Config.apiEndpoint,
      headers: {'accept': 'application/json'},
    }
    const req = await axios({...options, ...config})
    return req.data
  }catch(error){
    if(error.response){
      throw({status: error.response.status, message: error.response.data.message || error.response.data, trace: error.response.data.requestId || "Generic error"})
    }
    throw(error)
  }
}
